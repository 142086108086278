<template>
  <div class="retrieve_password">
    <van-steps :active="active" active-color="#F3AD3D">
      <van-step>填写用户名</van-step>
      <van-step>填写密码</van-step>
      <van-step>完成</van-step>
    </van-steps>
    <template v-if="active === 0">
      <van-field
        v-model="tel"
        type="tel"
        label="登录账号"
        placeholder="请输入登录账号"
        maxlength="11"
        clear-trigger="focus"
      />
      <van-field
        v-model="captcha"
        type="tel"
        label="随机号码"
        placeholder="请输入随机号码"
        maxlength="6"
      >
        <template #button
          ><span style="color: #009a4d" v-show="!codeShow" @click="getCode"
            >获取验证码</span
          >
          <span v-show="codeShow">{{ codeShow }}s后再获取</span></template
        ></van-field
      >
    </template>
    <template v-else-if="active === 1">
      <van-field
        v-model="password"
        type="password"
        label="新密码"
        placeholder="请输入新密码"
        maxlength="32"
      />
      <van-field
        v-model="password2"
        type="password"
        label="确认密码"
        placeholder="请再次确认密码"
        maxlength="32"
      />
    </template>
    <div class="succeed" v-else>
      <img src="@/assets/images/login_prosperity.png" alt="" />
      <p>您的密码已修改成功</p>
    </div>
    <p class="Error" v-show="error">
      <svg-icon icon-class="login_note" />{{ error }}
    </p>
    <p class="Error" v-show="!error && active != 2"></p>
    <van-button block color="#009A4D" @click="submit">{{
      active > 1 ? "立即登录" : "下一步"
    }}</van-button>
    <p class="go_back" v-if="active === 2">{{ back }}s后返回登录中心</p>
  </div>
</template>
	
<script>
export default {
  name: "retrieve-password",

  data() {
    return {
      active: 0,
      tel: "",
      captcha: "",
      codeShow: 0,
      password: "",
      password2: "",
      error: "",
      back: 5,
    };
  },

  mounted() {},
  beforeDestroy() {
    clearInterval(this.t);
    clearInterval(this.t2);
  },
  methods: {
    submit() {
      this.error = "";
      if (this.active === 0) {
        if (!/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.tel)) {
          this.error = "请输入正确的手机号码";
        } else if (this.captcha === "") {
          this.error = "请输入验证码";
        } else {
          this.verificationCAPTCHA();
        }
      } else if (this.active === 1) {
        if (this.password == "") {
          this.error = "请输入密码";
        } else if (this.password2 == "") {
          this.error = "请再次输入密码";
        } else if (this.password != this.password2) {
          this.error = "两次密码输入不一致";
        } else if (this.password.length < 6) {
          this.error = "密码最少为6位";
        } else {
          this.error = "";
          this.updatePasswd();
        }
      } else if (this.active === 2) {
        this.$router.go(-1);
      }
    },
    //获取验证码
    async getCode() {
      this.error = "";
      if (/^(?:(?:\+|00)86)?1[3-9]\d{9}$/.test(this.tel)) {
        await this.$API.login
          .sendCAPTCHA({
            mobile: this.tel,
          })
          .then((res) => {
            this.Toast.success("发送成功");
            this.codeShow = 59;
            this.t = setInterval(() => {
              if (this.codeShow) {
                this.codeShow--;
              } else {
                clearInterval(this.t);
              }
            }, 1000);
          })
          .catch(() => {
            this.Toast.fail("发送失败");
          });
      } else {
        this.error = "请输入正确的手机号码";
      }
    },
    //校验验证码
    async verificationCAPTCHA() {
      this.error = "";
      await this.$API.login
        .verificationCAPTCHA({
          mobile: this.tel,
          captcha: this.captcha,
        })
        .then((res) => {
          if (res.data.data == null) {
            // ("验证码错误");
            this.error = "验证码错误";
          } else if (res.data.data.result) {
            this.active++;
          } else {
            this.Toast.fail(res.data.msg);
          }
        });
    },
    async updatePasswd() {
      await this.$API.user
        .updatePasswdByUserName({
          object: {
            // captcha: this.ruleForm.authCode,
            captcha: this.captcha,
            password: this.password2,
            username: this.tel,
          },
        })
        .then((res) => {
          if (res.data.code === "SUCCESS") {
            this.active++;
            this.t2 = setInterval(() => {
              this.back--;
              if (this.back < 1) {
                this.$router.go(-1);
              }
            }, 1000);
            // this.$router.replace("/Login");
          } else {
            this.Toast.fail(res.data.msg);
          }
        })
        .catch(() => {
          this.Toast.fail(res.data.msg);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.retrieve_password {
  position: relative;
  top: 46px;
  padding: 20px 30px;
  ::v-deep .van-steps {
    margin-bottom: 20px;
    .van-steps__items {
      padding-bottom: 0;
    }
    .van-hairline {
      margin-top: 30px;
    }
    .van-step__title {
      font-size: 14px;
    }
    .van-step__circle-container,
    .van-step__line {
      top: -20px;
    }
    .van-icon {
      font-size: 24px;
    }
  }
  ::v-deep .van-cell {
    padding-left: 0;
    padding-right: 0;
    .van-cell__title {
      width: 56px;
      margin-right: 24px;
    }
  }
  .van-cell::after {
    left: 0;
    right: 0;
  }
  .Error {
    .svg-icon {
      margin-right: 6px;
    }
    font-size: 12px;
    height: 18px;
    color: #f61423;
    margin: 8px 0 24px;
  }
  .van-button {
    border-radius: 5px;
  }
  .succeed {
    text-align: center;
    margin-bottom: 46px;
    padding-top: 10px;
    img {
      width: 48px;
    }
    p {
      margin-top: 9px;
      font-size: 16px;
      line-height: 23px;
      color: #000000;
    }
  }
  .go_back {
    text-align: center;
    margin-top: 10px;
    color: #999999;
    opacity: 1;
  }
}
</style>